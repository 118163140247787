<template>
  <div>
    <router-link :to="{name: 'WelcomeLanding'}">
      <UIImage class="mb-12" width="130" :name-path="`logo.svg`"/>
    </router-link>

    <div class="mb-2 text-captions-1 gray-60--text">Welcome back! 👋</div>
    <EnterSocialEmail v-if="enterSocialEmailForm"/>

    <template v-else>
      <h3 class="mb-8 text-title-2 gray-100--text">Log in to your account.</h3>

      <social-list
        class="mb-9"
        :firebase-app="firebaseApp"
        @onSocialAuth="auth"
        @toggleEmailForm="enterSocialEmailForm = true"
      />
      <p class="mb-8 text-captions-1 grey--text">Or use your email for logging in</p>

      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <v-form @submit.prevent="handleSubmit(submit)" class="relative mb-13">
          <div v-if="isLoading" class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white" style="z-index: 2">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate/>
          </div>

          <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                              name="your e-mail" vid="email" rules="required|email" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">E-mail</div>
            <v-text-field
              v-model="form.email"
              type="email"
              outlined
              hide-details
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Email"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-10" mode="eager" tag="div"
                              name="your password" vid="password" rules="required|min:8|max:100" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Password</div>
            <v-text-field
              v-model="form.password"
              type="password"
              outlined
              hide-details
              @keydown.space.prevent
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Password"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <UiBtn
            type="submit"
            width="100%"
            height="40"
            color="accent"
            class="mb-3"
            :disabled="isFormFieldEmpty || invalid"
          >
            Log in
          </UiBtn>

          <div class="text-center">
            <router-link :to="{name: 'ForgotPassword'}" class="text-captions-1 text-decoration-none font-weight-bold accent-80--text">
              Forget your password?
            </router-link>
          </div>
        </v-form>
      </ValidationObserver>

      <p class="mb-0 text-captions-1 gray-100--text text-center">
        Don’t have an account? <router-link :to="{name: 'Registration'}" class="accent-80--text">Sign Up</router-link>
      </p>
    </template>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import SocialList from "@/views/auth/SocialList";
import EnterSocialEmail from "@/views/auth/EnterSocialEmail";
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'Login',
  props: {
    firebaseApp: {
      required: true,
    }
  },
  components: {
    SocialList,
    EnterSocialEmail,
    UIImage,
    UiBtn,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        password: ''
      },
      enterSocialEmailForm: false,
    }
  },
  computed: {
    isFormFieldEmpty() {
      return Object.keys(this.form).some(key => this.form[key] === '');
    }
  },
  created() {
    const socialAuth = JSON.parse(localStorage.getItem('socialAuth'));
    if (socialAuth && !socialAuth.email) {
      this.enterSocialEmailForm = true;
    }

    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    async submit () {
      this.isLoading = true;

      try {
        const auth = getAuth(this.firebaseApp);
        const userCredential = await signInWithEmailAndPassword(auth, this.form.email, this.form.password)
        const user = userCredential.user;

        await this.auth(user.accessToken);
      } catch (error) {
        this.isLoading = false;

        if (error.code === 'auth/user-not-found') {
          this.$refs.form.setErrors({email: "User with this email address doesn't exist"});
        } else if (error.code === 'auth/wrong-password') {
          this.$refs.form.setErrors({password: 'Wrong password'});
        }

        console.error(error);
      }
    },
    async auth(token) {
      this.isLoading = true;
      try {
        const res = await this.$api.auth.login({token});
        await this.$store.dispatch('setAuth', res);

        this.$toast.open({
          message: 'Welcome back to Upsway!',
          type: 'success',
          position: 'top-right'
        });
        await this.$router.push({name: "GlobalDashboard"});
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>
