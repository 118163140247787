<template>
  <div>
    <router-link :to="{name: 'WelcomeLanding'}">
      <UIImage class="mb-16" width="130" :name-path="`logo.svg`"/>
    </router-link>

    <h3 class="mb-2 text-title-2 gray-100--text">Forgot your password?</h3>
    <p class="mb-7 text-captions-1 gray-100--text">Dont worry, it happens.</p>
    <p class="mb-7 text-captions-1 grey-60--text">Fill in the email used for your account, you will receive a recovery link.</p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)" class="relative">
        <div v-if="isLoading" class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white" style="z-index: 2">
          <v-progress-circular
            :size="100"
            color="accent"
            indeterminate/>
        </div>

        <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                            name="your e-mail" vid="email" rules="required|email" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">E-mail</div>
          <v-text-field
            v-model="form.email"
            type="email"
            outlined
            hide-details
            :disabled="isLoading"
            :error="!!errors.length"
            placeholder="Email"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <UiBtn
          type="submit"
          width="100%"
          height="40"
          color="accent"
          :disabled="form.email === ''"
        >
          Email Link
        </UiBtn>

        <div v-if="isSuccess" class="alert d-flex align-center mt-7 px-3 rounded text-captions-1 success--text">
          <IconCheckFilled width="17" class="mr-3"/>
          Email Sent
        </div>

        <div v-if="isError" class="alert d-flex align-center mt-7 px-3 rounded text-captions-1 error--text">
          <IconCancelFilled width="17" class="mr-3"/>
          There was a problem
        </div>

      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'ForgotPassword',
  components: {
    IconCheckFilled: () => import('@/components/icons/IconCheckFilled'),
    IconCancelFilled: () => import('@/components/icons/IconCancelFilled'),

    UIImage,
    UiBtn,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      isError: false,
      form: {
        email: '',
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true;
      this.isSuccess = false;
      this.isError = false;

      try {
        await this.$api.auth.recoverPassword(this.form);
        this.isSuccess = true;
        // await this.$router.push({name: "Login"});
      } catch (error) {
        console.error(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.alert {
  height: 40px;
  border: 1px solid currentColor;
}
</style>
