<template>
  <div>
    <router-link :to="{name: 'WelcomeLanding'}">
      <UIImage class="mb-16" width="130" :name-path="`logo.svg`"/>
    </router-link>

    <h3 class="mb-2 text-title-2 gray-100--text">Forgot your password?</h3>
    <p class="mb-7 text-captions-1 gray-100--text">Type in your new password</p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <v-form @submit.prevent="handleSubmit(submit)" class="relative">
        <div v-if="isLoading" class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white" style="z-index: 2">
          <v-progress-circular
            :size="100"
            color="accent"
            indeterminate/>
        </div>

        <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                            name="your new password" vid="password" rules="required|min:8|max:100|unique_chars:5|password_spec_char" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">Password</div>
          <v-text-field
            v-model="form.password"
            type="password"
            outlined
            hide-details
            @keydown.space.prevent
            :disabled="isLoading"
            :error="!!errors.length"
            placeholder="Password"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <ValidationProvider class="input-wrap mb-8" mode="eager" tag="div"
                            name="your new password" vid="password_repeat" rules="required|min:8|max:100|unique_chars:5|password_spec_char|confirmed:password" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">Repeat new password</div>
          <v-text-field
            v-model="form.password_confirmation"
            type="password"
            outlined
            hide-details
            @keydown.space.prevent
            :disabled="isLoading"
            :error="!!errors.length"
            placeholder="Password"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <div class="password-tip mb-8 px-3 rounded text-captions-2">
          <ul>
            <li>At least 8 characters (and up to 100 characters)</li>
            <li>5 or more unique characters.</li>
            <li>
              At least 1 of the following: uppercase, lowercase, numeric, or special characters. The allowed special characters are:
              <br>
              ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ?
            </li>
          </ul>
        </div>

        <UiBtn
          type="submit"
          width="100%"
          height="40"
          color="accent"
          class="mb-3"
          :disabled="isFormFieldEmpty || invalid"
        >
          Change Password
        </UiBtn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'ResetPassword',
  components: {
    UIImage,
    UiBtn,
  },
  data() {
    return {
      isLoading: false,
      form: {
        token: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  computed: {
    isFormFieldEmpty() {
      return Object.keys(this.form).some(key => this.form[key] === '');
    }
  },
  created() {
    this.form.token = this.$route.query.token;
  },
  methods: {
    async submit() {
      this.isLoading = true;

      try {
        await this.$api.auth.resetPassword(this.form);

        this.$toast.open({
          message: 'Your password has been updated successfully',
          type: 'success',
          position: 'top-right'
        });

        this.$router.push({name: "Login"});
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.password-tip {
  padding: 14px 0;
  border: 1px solid var(--v-gray-30-base);

  ul {
    padding-left: 10px;
  }
}
</style>
