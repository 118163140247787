<template>
  <div>
    <UiBtn
      width="60"
      height="60"
      outlined
      color="gray-30"
      class="rounded-0 mr-5"
      @click="googleAuth"
    >
      <IconGoogle  width="24" height="24" class="gray-60--text"/>
    </UiBtn>

<!--    We have to hide facebook until we get prod credentials for it-->
    <UiBtn
      v-if="!isProd"
      width="60"
      height="60"
      outlined
      color="gray-30"
      class="rounded-0 mr-5"
      @click="facebookAuth"
    >
      <IconFacebook  width="24" height="24" class="gray-60--text"/>
    </UiBtn>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import {getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider} from "firebase/auth";

export default {
  name: 'SocialList',
  props: {
    firebaseApp: {
      required: true,
    }
  },
  components: {
    IconGoogle: () => import('@/components/icons/IconGoogle'),
    IconFacebook: () => import('@/components/icons/IconFacebook'),
    UiBtn,
  },
  computed: {
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT === 'production';
    }
  },
  methods: {
    googleAuth() {
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      this.auth(provider);
    },
    facebookAuth() {
      const provider = new FacebookAuthProvider();
      this.auth(provider);
    },
    async auth(customProviderInstance) {
      const auth = getAuth(this.firebaseApp);

      try {
        const result = await signInWithPopup(auth, customProviderInstance);

        const providerDataUser = result?.user?.providerData?.find?.(provider => provider.providerId === result.providerId)

        if (result.user.email === null && !providerDataUser?.email) {
          localStorage.setItem('socialAuth', JSON.stringify({email: null, uid: result.user.uid}))
          this.$emit('toggleEmailForm');
          return;
        }

        this.$emit('onSocialAuth', result.user.accessToken);
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.ui-btn {
  background-color: var(--v-gray-10-base);
}
</style>
