<template>
  <div :class="['auth-page d-flex fill-width fill-height', {'blur-bg': isAuth}]">
    <div class="auth-wrap d-flex justify-end py-12">
      <div class="auth d-flex flex-column pt-12 px-11 pb-11 fill-width rounded gray-0">
        <component :is="$route.name" :firebaseApp="firebaseApp"/>
      </div>
    </div>

    <div class="auth-image d-flex fill-height">
      <img v-if="isAuth" :src="require('@/assets/images/auth/login.png')" alt="" class="fill-width fill-height object-cover">
      <img v-else :src="require('@/assets/images/auth/login-2.png')" alt="" class="fill-width fill-height object-cover">
    </div>
  </div>
</template>

<script>
import {initializeApp} from "firebase/app";
import SocialList from "@/views/auth/SocialList";
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";
import Login from "@/views/auth/Login";
import Registration from "@/views/auth/Registration";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import VerifyEmail from "@/views/auth/VerifyEmail";

export default {
  name: 'LoginPage',
  components: {
    SocialList,
    UIImage,
    UiBtn,
    Login,
    Registration,
    ForgotPassword,
    ResetPassword,
    VerifyEmail,
  },
  data() {
    return {
      firebaseApp: null
    }
  },
  computed: {
    isAuth() {
      return this.$route.name === 'Login' || this.$route.name === 'Registration';
    }
  },
  created() {
    this.firebaseApp = initializeApp({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    });
  },
}
</script>

<style scoped lang="scss">
.auth-page {
  background: transparent url("~@/assets/images/auth/bg-pattern.png") repeat;
  &.blur-bg {
    background: linear-gradient(90deg, #DCDDDE 14.08%, rgba(255, 255, 255, 0) 100%), url("~@/assets/images/auth/bg-pattern.png") repeat;
  }
}

.auth-wrap {
  width: 47%;
  padding-left: 50px;
  align-items: flex-start;

  @media screen and (min-width: 2560px) {
    align-items: center;
  }
}

.auth {
  margin-right: 90px;
  max-width: 444px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.09);

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    margin-right: 50px;
  }
}

.auth-image {
  width: 53%;
}

::v-deep .input-wrap .v-input__slot {
  min-height: 45px;

  input {
    font-size: 12px;
    color: var(--v-gray-60-base);
  }
}
</style>
